/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addMemberToProject } from '../fn/nationwide-projects-controller/add-member-to-project';
import { AddMemberToProject$Params } from '../fn/nationwide-projects-controller/add-member-to-project';
import { assignSecurityExpertToProjectMember } from '../fn/nationwide-projects-controller/assign-security-expert-to-project-member';
import { AssignSecurityExpertToProjectMember$Params } from '../fn/nationwide-projects-controller/assign-security-expert-to-project-member';
import { createProject } from '../fn/nationwide-projects-controller/create-project';
import { CreateProject$Params } from '../fn/nationwide-projects-controller/create-project';
import { CustomerOverviewDto } from '../models/customer-overview-dto';
import { deleteMemberOfProject } from '../fn/nationwide-projects-controller/delete-member-of-project';
import { DeleteMemberOfProject$Params } from '../fn/nationwide-projects-controller/delete-member-of-project';
import { deleteProject } from '../fn/nationwide-projects-controller/delete-project';
import { DeleteProject$Params } from '../fn/nationwide-projects-controller/delete-project';
import { getAllProjects } from '../fn/nationwide-projects-controller/get-all-projects';
import { GetAllProjects$Params } from '../fn/nationwide-projects-controller/get-all-projects';
import { getProjectMembers } from '../fn/nationwide-projects-controller/get-project-members';
import { GetProjectMembers$Params } from '../fn/nationwide-projects-controller/get-project-members';
import { ProjectDto } from '../models/project-dto';
import { updateProject } from '../fn/nationwide-projects-controller/update-project';
import { UpdateProject$Params } from '../fn/nationwide-projects-controller/update-project';


/**
 * manage nationwide projects
 */
@Injectable({ providedIn: 'root' })
export class NationwideProjectsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `assignSecurityExpertToProjectMember()` */
  static readonly AssignSecurityExpertToProjectMemberPath = '/nationwide-projects/{projectId}/members/{customerId}/{securityExpertId}';

  /**
   * Assign security expert to project member.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignSecurityExpertToProjectMember()` instead.
   *
   * This method doesn't expect any request body.
   */
  assignSecurityExpertToProjectMember$Response(params: AssignSecurityExpertToProjectMember$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return assignSecurityExpertToProjectMember(this.http, this.rootUrl, params, context);
  }

  /**
   * Assign security expert to project member.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assignSecurityExpertToProjectMember$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  assignSecurityExpertToProjectMember(params: AssignSecurityExpertToProjectMember$Params, context?: HttpContext): Observable<void> {
    return this.assignSecurityExpertToProjectMember$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateProject()` */
  static readonly UpdateProjectPath = '/nationwide-projects/{id}';

  /**
   * Update a project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateProject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProject$Response(params: UpdateProject$Params, context?: HttpContext): Observable<StrictHttpResponse<ProjectDto>> {
    return updateProject(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateProject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProject(params: UpdateProject$Params, context?: HttpContext): Observable<ProjectDto> {
    return this.updateProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProjectDto>): ProjectDto => r.body)
    );
  }

  /** Path part for operation `deleteProject()` */
  static readonly DeleteProjectPath = '/nationwide-projects/{id}';

  /**
   * Delete project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteProject$Response(params: DeleteProject$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteProject(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteProject(params: DeleteProject$Params, context?: HttpContext): Observable<void> {
    return this.deleteProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAllProjects()` */
  static readonly GetAllProjectsPath = '/nationwide-projects';

  /**
   * Get all projects
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllProjects()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllProjects$Response(params: GetAllProjects$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProjectDto>>> {
    return getAllProjects(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all projects
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllProjects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllProjects(params: GetAllProjects$Params, context?: HttpContext): Observable<Array<ProjectDto>> {
    return this.getAllProjects$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProjectDto>>): Array<ProjectDto> => r.body)
    );
  }

  /** Path part for operation `createProject()` */
  static readonly CreateProjectPath = '/nationwide-projects';

  /**
   * Create a project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createProject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createProject$Response(params: CreateProject$Params, context?: HttpContext): Observable<StrictHttpResponse<ProjectDto>> {
    return createProject(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createProject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createProject(params: CreateProject$Params, context?: HttpContext): Observable<ProjectDto> {
    return this.createProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProjectDto>): ProjectDto => r.body)
    );
  }

  /** Path part for operation `getProjectMembers()` */
  static readonly GetProjectMembersPath = '/nationwide-projects/{id}/members';

  /**
   * Get project members
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProjectMembers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProjectMembers$Response(params: GetProjectMembers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CustomerOverviewDto>>> {
    return getProjectMembers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get project members
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProjectMembers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProjectMembers(params: GetProjectMembers$Params, context?: HttpContext): Observable<Array<CustomerOverviewDto>> {
    return this.getProjectMembers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CustomerOverviewDto>>): Array<CustomerOverviewDto> => r.body)
    );
  }

  /** Path part for operation `addMemberToProject()` */
  static readonly AddMemberToProjectPath = '/nationwide-projects/{id}/members';

  /**
   * Add a member to project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addMemberToProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  addMemberToProject$Response(params: AddMemberToProject$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CustomerOverviewDto>>> {
    return addMemberToProject(this.http, this.rootUrl, params, context);
  }

  /**
   * Add a member to project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addMemberToProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addMemberToProject(params: AddMemberToProject$Params, context?: HttpContext): Observable<Array<CustomerOverviewDto>> {
    return this.addMemberToProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CustomerOverviewDto>>): Array<CustomerOverviewDto> => r.body)
    );
  }

  /** Path part for operation `deleteMemberOfProject()` */
  static readonly DeleteMemberOfProjectPath = '/nationwide-projects/{id}/members/{customerId}';

  /**
   * Delete a member of project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMemberOfProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMemberOfProject$Response(params: DeleteMemberOfProject$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CustomerOverviewDto>>> {
    return deleteMemberOfProject(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a member of project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteMemberOfProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMemberOfProject(params: DeleteMemberOfProject$Params, context?: HttpContext): Observable<Array<CustomerOverviewDto>> {
    return this.deleteMemberOfProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CustomerOverviewDto>>): Array<CustomerOverviewDto> => r.body)
    );
  }

}
