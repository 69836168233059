/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ActualConditionSetDto } from '../models/actual-condition-set-dto';
import { checkIfActivationDateIsValid } from '../fn/condition/check-if-activation-date-is-valid';
import { CheckIfActivationDateIsValid$Params } from '../fn/condition/check-if-activation-date-is-valid';
import { checkIfExpirationDateIsValid } from '../fn/condition/check-if-expiration-date-is-valid';
import { CheckIfExpirationDateIsValid$Params } from '../fn/condition/check-if-expiration-date-is-valid';
import { checkIfValueIsValid } from '../fn/condition/check-if-value-is-valid';
import { CheckIfValueIsValid$Params } from '../fn/condition/check-if-value-is-valid';
import { ConditionModel } from '../models/condition-model';
import { createActualConditions } from '../fn/condition/create-actual-conditions';
import { CreateActualConditions$Params } from '../fn/condition/create-actual-conditions';
import { deleteActualConditions } from '../fn/condition/delete-actual-conditions';
import { DeleteActualConditions$Params } from '../fn/condition/delete-actual-conditions';
import { deleteConditionSetByRuntimeGroupId } from '../fn/condition/delete-condition-set-by-runtime-group-id';
import { DeleteConditionSetByRuntimeGroupId$Params } from '../fn/condition/delete-condition-set-by-runtime-group-id';
import { getActualConditions } from '../fn/condition/get-actual-conditions';
import { GetActualConditions$Params } from '../fn/condition/get-actual-conditions';
import { getActualConditionsById } from '../fn/condition/get-actual-conditions-by-id';
import { GetActualConditionsById$Params } from '../fn/condition/get-actual-conditions-by-id';
import { getConditionSets } from '../fn/condition/get-condition-sets';
import { GetConditionSets$Params } from '../fn/condition/get-condition-sets';
import { getCurrentActualConditions } from '../fn/condition/get-current-actual-conditions';
import { GetCurrentActualConditions$Params } from '../fn/condition/get-current-actual-conditions';
import { getStandardConditionsForBoxTypesAndSvg } from '../fn/condition/get-standard-conditions-for-box-types-and-svg';
import { GetStandardConditionsForBoxTypesAndSvg$Params } from '../fn/condition/get-standard-conditions-for-box-types-and-svg';
import { resetActualConditionsToStandardConditions } from '../fn/condition/reset-actual-conditions-to-standard-conditions';
import { ResetActualConditionsToStandardConditions$Params } from '../fn/condition/reset-actual-conditions-to-standard-conditions';
import { sendConditionChangeMail } from '../fn/condition/send-condition-change-mail';
import { SendConditionChangeMail$Params } from '../fn/condition/send-condition-change-mail';
import { sendConditionResetMail } from '../fn/condition/send-condition-reset-mail';
import { SendConditionResetMail$Params } from '../fn/condition/send-condition-reset-mail';
import { updateActualConditions } from '../fn/condition/update-actual-conditions';
import { UpdateActualConditions$Params } from '../fn/condition/update-actual-conditions';


/**
 * Condition operations
 */
@Injectable({ providedIn: 'root' })
export class ConditionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getActualConditionsById()` */
  static readonly GetActualConditionsByIdPath = '/condition-set/{runtimeGroupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActualConditionsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActualConditionsById$Response(params: GetActualConditionsById$Params, context?: HttpContext): Observable<StrictHttpResponse<ActualConditionSetDto>> {
    return getActualConditionsById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActualConditionsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActualConditionsById(params: GetActualConditionsById$Params, context?: HttpContext): Observable<ActualConditionSetDto> {
    return this.getActualConditionsById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActualConditionSetDto>): ActualConditionSetDto => r.body)
    );
  }

  /** Path part for operation `updateActualConditions()` */
  static readonly UpdateActualConditionsPath = '/condition-set/{runtimeGroupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateActualConditions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateActualConditions$Response(params: UpdateActualConditions$Params, context?: HttpContext): Observable<StrictHttpResponse<ActualConditionSetDto>> {
    return updateActualConditions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateActualConditions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateActualConditions(params: UpdateActualConditions$Params, context?: HttpContext): Observable<ActualConditionSetDto> {
    return this.updateActualConditions$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActualConditionSetDto>): ActualConditionSetDto => r.body)
    );
  }

  /** Path part for operation `deleteActualConditions()` */
  static readonly DeleteActualConditionsPath = '/condition-set/{runtimeGroupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteActualConditions()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteActualConditions$Response(params: DeleteActualConditions$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteActualConditions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteActualConditions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteActualConditions(params: DeleteActualConditions$Params, context?: HttpContext): Observable<void> {
    return this.deleteActualConditions$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createActualConditions()` */
  static readonly CreateActualConditionsPath = '/condition-set';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createActualConditions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createActualConditions$Response(params: CreateActualConditions$Params, context?: HttpContext): Observable<StrictHttpResponse<ActualConditionSetDto>> {
    return createActualConditions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createActualConditions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createActualConditions(params: CreateActualConditions$Params, context?: HttpContext): Observable<ActualConditionSetDto> {
    return this.createActualConditions$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActualConditionSetDto>): ActualConditionSetDto => r.body)
    );
  }

  /** Path part for operation `resetActualConditionsToStandardConditions()` */
  static readonly ResetActualConditionsToStandardConditionsPath = '/condition-set/{runtimeGroupId}/terminate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetActualConditionsToStandardConditions()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetActualConditionsToStandardConditions$Response(params: ResetActualConditionsToStandardConditions$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return resetActualConditionsToStandardConditions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resetActualConditionsToStandardConditions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetActualConditionsToStandardConditions(params: ResetActualConditionsToStandardConditions$Params, context?: HttpContext): Observable<void> {
    return this.resetActualConditionsToStandardConditions$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sendConditionChangeMail()` */
  static readonly SendConditionChangeMailPath = '/condition-set/{runtimeGroupId}/send-mail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendConditionChangeMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendConditionChangeMail$Response(params: SendConditionChangeMail$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sendConditionChangeMail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendConditionChangeMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendConditionChangeMail(params: SendConditionChangeMail$Params, context?: HttpContext): Observable<void> {
    return this.sendConditionChangeMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sendConditionResetMail()` */
  static readonly SendConditionResetMailPath = '/condition-set/reset-to-standard/send-mail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendConditionResetMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendConditionResetMail$Response(params: SendConditionResetMail$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sendConditionResetMail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendConditionResetMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendConditionResetMail(params: SendConditionResetMail$Params, context?: HttpContext): Observable<void> {
    return this.sendConditionResetMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getConditionSets()` */
  static readonly GetConditionSetsPath = '/conditions/{accountId}/{boxTypeId}/{svgId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConditionSets()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConditionSets$Response(params: GetConditionSets$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ActualConditionSetDto>>> {
    return getConditionSets(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConditionSets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConditionSets(params: GetConditionSets$Params, context?: HttpContext): Observable<Array<ActualConditionSetDto>> {
    return this.getConditionSets$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ActualConditionSetDto>>): Array<ActualConditionSetDto> => r.body)
    );
  }

  /** Path part for operation `checkIfValueIsValid()` */
  static readonly CheckIfValueIsValidPath = '/condition/value/validate';

  /**
   * Check if desired value change is allowed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkIfValueIsValid()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkIfValueIsValid$Response(params: CheckIfValueIsValid$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return checkIfValueIsValid(this.http, this.rootUrl, params, context);
  }

  /**
   * Check if desired value change is allowed
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkIfValueIsValid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkIfValueIsValid(params: CheckIfValueIsValid$Params, context?: HttpContext): Observable<void> {
    return this.checkIfValueIsValid$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `checkIfExpirationDateIsValid()` */
  static readonly CheckIfExpirationDateIsValidPath = '/condition/expiration-date/validate';

  /**
   * Check if desired expiration date is valid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkIfExpirationDateIsValid()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkIfExpirationDateIsValid$Response(params: CheckIfExpirationDateIsValid$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return checkIfExpirationDateIsValid(this.http, this.rootUrl, params, context);
  }

  /**
   * Check if desired expiration date is valid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkIfExpirationDateIsValid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkIfExpirationDateIsValid(params: CheckIfExpirationDateIsValid$Params, context?: HttpContext): Observable<void> {
    return this.checkIfExpirationDateIsValid$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `checkIfActivationDateIsValid()` */
  static readonly CheckIfActivationDateIsValidPath = '/condition/activation-date/validate';

  /**
   * Check if desired activation date is valid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkIfActivationDateIsValid()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkIfActivationDateIsValid$Response(params: CheckIfActivationDateIsValid$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return checkIfActivationDateIsValid(this.http, this.rootUrl, params, context);
  }

  /**
   * Check if desired activation date is valid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkIfActivationDateIsValid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkIfActivationDateIsValid(params: CheckIfActivationDateIsValid$Params, context?: HttpContext): Observable<void> {
    return this.checkIfActivationDateIsValid$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getActualConditions()` */
  static readonly GetActualConditionsPath = '/account-type/{accountTypeId}/box-type/{boxTypeId}/svgs/{svgId}/conditions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActualConditions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActualConditions$Response(params: GetActualConditions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ActualConditionSetDto>>> {
    return getActualConditions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActualConditions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActualConditions(params: GetActualConditions$Params, context?: HttpContext): Observable<Array<ActualConditionSetDto>> {
    return this.getActualConditions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ActualConditionSetDto>>): Array<ActualConditionSetDto> => r.body)
    );
  }

  /** Path part for operation `getCurrentActualConditions()` */
  static readonly GetCurrentActualConditionsPath = '/account-type/{accountTypeId}/box-type/{boxTypeId}/svgs/{svgId}/conditions/current';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentActualConditions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentActualConditions$Response(params: GetCurrentActualConditions$Params, context?: HttpContext): Observable<StrictHttpResponse<ActualConditionSetDto>> {
    return getCurrentActualConditions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentActualConditions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentActualConditions(params: GetCurrentActualConditions$Params, context?: HttpContext): Observable<ActualConditionSetDto> {
    return this.getCurrentActualConditions$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActualConditionSetDto>): ActualConditionSetDto => r.body)
    );
  }

  /** Path part for operation `getStandardConditionsForBoxTypesAndSvg()` */
  static readonly GetStandardConditionsForBoxTypesAndSvgPath = '/account-type/box-type/{boxTypeId}/svgs/{svgId}/standard-conditions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStandardConditionsForBoxTypesAndSvg()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStandardConditionsForBoxTypesAndSvg$Response(params: GetStandardConditionsForBoxTypesAndSvg$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConditionModel>>> {
    return getStandardConditionsForBoxTypesAndSvg(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStandardConditionsForBoxTypesAndSvg$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStandardConditionsForBoxTypesAndSvg(params: GetStandardConditionsForBoxTypesAndSvg$Params, context?: HttpContext): Observable<Array<ConditionModel>> {
    return this.getStandardConditionsForBoxTypesAndSvg$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ConditionModel>>): Array<ConditionModel> => r.body)
    );
  }

  /** Path part for operation `deleteConditionSetByRuntimeGroupId()` */
  static readonly DeleteConditionSetByRuntimeGroupIdPath = '/accounts/{accountId}/condition-sets/{runtimeGroupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteConditionSetByRuntimeGroupId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteConditionSetByRuntimeGroupId$Response(params: DeleteConditionSetByRuntimeGroupId$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteConditionSetByRuntimeGroupId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteConditionSetByRuntimeGroupId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteConditionSetByRuntimeGroupId(params: DeleteConditionSetByRuntimeGroupId$Params, context?: HttpContext): Observable<void> {
    return this.deleteConditionSetByRuntimeGroupId$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
