/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ResponseObject } from '../models/response-object';
import { sendConditionsAdjustmentMail } from '../fn/mail-controller/send-conditions-adjustment-mail';
import { SendConditionsAdjustmentMail$Params } from '../fn/mail-controller/send-conditions-adjustment-mail';
import { sendContactMail } from '../fn/mail-controller/send-contact-mail';
import { SendContactMail$Params } from '../fn/mail-controller/send-contact-mail';
import { sendCrefoMailboxMail } from '../fn/mail-controller/send-crefo-mailbox-mail';
import { SendCrefoMailboxMail$Params } from '../fn/mail-controller/send-crefo-mailbox-mail';
import { sendCustomerBusinessAreaLeadMail } from '../fn/mail-controller/send-customer-business-area-lead-mail';
import { SendCustomerBusinessAreaLeadMail$Params } from '../fn/mail-controller/send-customer-business-area-lead-mail';
import { sendDkvEInvoicingMail } from '../fn/mail-controller/send-dkv-e-invoicing-mail';
import { SendDkvEInvoicingMail$Params } from '../fn/mail-controller/send-dkv-e-invoicing-mail';
import { sendEetsOrderConfirmedMail } from '../fn/mail-controller/send-eets-order-confirmed-mail';
import { SendEetsOrderConfirmedMail$Params } from '../fn/mail-controller/send-eets-order-confirmed-mail';
import { sendEetsOrderOfferMail } from '../fn/mail-controller/send-eets-order-offer-mail';
import { SendEetsOrderOfferMail$Params } from '../fn/mail-controller/send-eets-order-offer-mail';
import { sendEetsOrderRequestMail } from '../fn/mail-controller/send-eets-order-request-mail';
import { SendEetsOrderRequestMail$Params } from '../fn/mail-controller/send-eets-order-request-mail';
import { sendFuelCardOrderMail } from '../fn/mail-controller/send-fuel-card-order-mail';
import { SendFuelCardOrderMail$Params } from '../fn/mail-controller/send-fuel-card-order-mail';
import { sendGenericNotificationMail } from '../fn/mail-controller/send-generic-notification-mail';
import { SendGenericNotificationMail$Params } from '../fn/mail-controller/send-generic-notification-mail';
import { sendProcessReminderCustomerMail } from '../fn/mail-controller/send-process-reminder-customer-mail';
import { SendProcessReminderCustomerMail$Params } from '../fn/mail-controller/send-process-reminder-customer-mail';
import { sendProcessReminderEmployeeMail } from '../fn/mail-controller/send-process-reminder-employee-mail';
import { SendProcessReminderEmployeeMail$Params } from '../fn/mail-controller/send-process-reminder-employee-mail';
import { sendRegisterNewMail } from '../fn/mail-controller/send-register-new-mail';
import { SendRegisterNewMail$Params } from '../fn/mail-controller/send-register-new-mail';
import { sendRegistrationFailMail } from '../fn/mail-controller/send-registration-fail-mail';
import { SendRegistrationFailMail$Params } from '../fn/mail-controller/send-registration-fail-mail';
import { sendRvMailBoxMail } from '../fn/mail-controller/send-rv-mail-box-mail';
import { SendRvMailBoxMail$Params } from '../fn/mail-controller/send-rv-mail-box-mail';
import { sendSafetiesIncidentMail } from '../fn/mail-controller/send-safeties-incident-mail';
import { SendSafetiesIncidentMail$Params } from '../fn/mail-controller/send-safeties-incident-mail';
import { sendTcPaymentMail } from '../fn/mail-controller/send-tc-payment-mail';
import { SendTcPaymentMail$Params } from '../fn/mail-controller/send-tc-payment-mail';
import { sendTelematicDeactivationMail } from '../fn/mail-controller/send-telematic-deactivation-mail';
import { SendTelematicDeactivationMail$Params } from '../fn/mail-controller/send-telematic-deactivation-mail';
import { sendTelematicOrderMail } from '../fn/mail-controller/send-telematic-order-mail';
import { SendTelematicOrderMail$Params } from '../fn/mail-controller/send-telematic-order-mail';
import { sendVignetteBookingMail } from '../fn/mail-controller/send-vignette-booking-mail';
import { SendVignetteBookingMail$Params } from '../fn/mail-controller/send-vignette-booking-mail';
import { sendVignetteCancellationMail } from '../fn/mail-controller/send-vignette-cancellation-mail';
import { SendVignetteCancellationMail$Params } from '../fn/mail-controller/send-vignette-cancellation-mail';
import { sendVignetteResubmissionMail } from '../fn/mail-controller/send-vignette-resubmission-mail';
import { SendVignetteResubmissionMail$Params } from '../fn/mail-controller/send-vignette-resubmission-mail';

@Injectable({ providedIn: 'root' })
export class MailControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sendVignetteResubmissionMail()` */
  static readonly SendVignetteResubmissionMailPath = '/sendMail/vignette-resubmission';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendVignetteResubmissionMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendVignetteResubmissionMail$Response(params: SendVignetteResubmissionMail$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseObject>> {
    return sendVignetteResubmissionMail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendVignetteResubmissionMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendVignetteResubmissionMail(params: SendVignetteResubmissionMail$Params, context?: HttpContext): Observable<ResponseObject> {
    return this.sendVignetteResubmissionMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseObject>): ResponseObject => r.body)
    );
  }

  /** Path part for operation `sendVignetteCancellationMail()` */
  static readonly SendVignetteCancellationMailPath = '/sendMail/vignette-cancellation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendVignetteCancellationMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendVignetteCancellationMail$Response(params: SendVignetteCancellationMail$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseObject>> {
    return sendVignetteCancellationMail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendVignetteCancellationMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendVignetteCancellationMail(params: SendVignetteCancellationMail$Params, context?: HttpContext): Observable<ResponseObject> {
    return this.sendVignetteCancellationMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseObject>): ResponseObject => r.body)
    );
  }

  /** Path part for operation `sendVignetteBookingMail()` */
  static readonly SendVignetteBookingMailPath = '/sendMail/vignette-booking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendVignetteBookingMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendVignetteBookingMail$Response(params: SendVignetteBookingMail$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseObject>> {
    return sendVignetteBookingMail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendVignetteBookingMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendVignetteBookingMail(params: SendVignetteBookingMail$Params, context?: HttpContext): Observable<ResponseObject> {
    return this.sendVignetteBookingMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseObject>): ResponseObject => r.body)
    );
  }

  /** Path part for operation `sendTelematicOrderMail()` */
  static readonly SendTelematicOrderMailPath = '/sendMail/telematic-order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendTelematicOrderMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendTelematicOrderMail$Response(params: SendTelematicOrderMail$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseObject>> {
    return sendTelematicOrderMail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendTelematicOrderMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendTelematicOrderMail(params: SendTelematicOrderMail$Params, context?: HttpContext): Observable<ResponseObject> {
    return this.sendTelematicOrderMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseObject>): ResponseObject => r.body)
    );
  }

  /** Path part for operation `sendTelematicDeactivationMail()` */
  static readonly SendTelematicDeactivationMailPath = '/sendMail/telematic-deactivation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendTelematicDeactivationMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendTelematicDeactivationMail$Response(params: SendTelematicDeactivationMail$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseObject>> {
    return sendTelematicDeactivationMail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendTelematicDeactivationMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendTelematicDeactivationMail(params: SendTelematicDeactivationMail$Params, context?: HttpContext): Observable<ResponseObject> {
    return this.sendTelematicDeactivationMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseObject>): ResponseObject => r.body)
    );
  }

  /** Path part for operation `sendTcPaymentMail()` */
  static readonly SendTcPaymentMailPath = '/sendMail/tc-payment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendTcPaymentMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendTcPaymentMail$Response(params: SendTcPaymentMail$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseObject>> {
    return sendTcPaymentMail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendTcPaymentMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendTcPaymentMail(params: SendTcPaymentMail$Params, context?: HttpContext): Observable<ResponseObject> {
    return this.sendTcPaymentMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseObject>): ResponseObject => r.body)
    );
  }

  /** Path part for operation `sendSafetiesIncidentMail()` */
  static readonly SendSafetiesIncidentMailPath = '/sendMail/safeties-incident-notification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendSafetiesIncidentMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendSafetiesIncidentMail$Response(params: SendSafetiesIncidentMail$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseObject>> {
    return sendSafetiesIncidentMail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendSafetiesIncidentMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendSafetiesIncidentMail(params: SendSafetiesIncidentMail$Params, context?: HttpContext): Observable<ResponseObject> {
    return this.sendSafetiesIncidentMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseObject>): ResponseObject => r.body)
    );
  }

  /** Path part for operation `sendRvMailBoxMail()` */
  static readonly SendRvMailBoxMailPath = '/sendMail/rv-mailbox';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendRvMailBoxMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendRvMailBoxMail$Response(params: SendRvMailBoxMail$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseObject>> {
    return sendRvMailBoxMail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendRvMailBoxMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendRvMailBoxMail(params: SendRvMailBoxMail$Params, context?: HttpContext): Observable<ResponseObject> {
    return this.sendRvMailBoxMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseObject>): ResponseObject => r.body)
    );
  }

  /** Path part for operation `sendRegistrationFailMail()` */
  static readonly SendRegistrationFailMailPath = '/sendMail/registration-fail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendRegistrationFailMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendRegistrationFailMail$Response(params: SendRegistrationFailMail$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseObject>> {
    return sendRegistrationFailMail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendRegistrationFailMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendRegistrationFailMail(params: SendRegistrationFailMail$Params, context?: HttpContext): Observable<ResponseObject> {
    return this.sendRegistrationFailMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseObject>): ResponseObject => r.body)
    );
  }

  /** Path part for operation `sendRegisterNewMail()` */
  static readonly SendRegisterNewMailPath = '/sendMail/register-new';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendRegisterNewMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendRegisterNewMail$Response(params: SendRegisterNewMail$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseObject>> {
    return sendRegisterNewMail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendRegisterNewMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendRegisterNewMail(params: SendRegisterNewMail$Params, context?: HttpContext): Observable<ResponseObject> {
    return this.sendRegisterNewMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseObject>): ResponseObject => r.body)
    );
  }

  /** Path part for operation `sendProcessReminderEmployeeMail()` */
  static readonly SendProcessReminderEmployeeMailPath = '/sendMail/process-reminder-employee';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendProcessReminderEmployeeMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendProcessReminderEmployeeMail$Response(params: SendProcessReminderEmployeeMail$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseObject>> {
    return sendProcessReminderEmployeeMail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendProcessReminderEmployeeMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendProcessReminderEmployeeMail(params: SendProcessReminderEmployeeMail$Params, context?: HttpContext): Observable<ResponseObject> {
    return this.sendProcessReminderEmployeeMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseObject>): ResponseObject => r.body)
    );
  }

  /** Path part for operation `sendProcessReminderCustomerMail()` */
  static readonly SendProcessReminderCustomerMailPath = '/sendMail/process-reminder-customer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendProcessReminderCustomerMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendProcessReminderCustomerMail$Response(params: SendProcessReminderCustomerMail$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseObject>> {
    return sendProcessReminderCustomerMail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendProcessReminderCustomerMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendProcessReminderCustomerMail(params: SendProcessReminderCustomerMail$Params, context?: HttpContext): Observable<ResponseObject> {
    return this.sendProcessReminderCustomerMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseObject>): ResponseObject => r.body)
    );
  }

  /** Path part for operation `sendGenericNotificationMail()` */
  static readonly SendGenericNotificationMailPath = '/sendMail/generic-notification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendGenericNotificationMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendGenericNotificationMail$Response(params: SendGenericNotificationMail$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseObject>> {
    return sendGenericNotificationMail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendGenericNotificationMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendGenericNotificationMail(params: SendGenericNotificationMail$Params, context?: HttpContext): Observable<ResponseObject> {
    return this.sendGenericNotificationMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseObject>): ResponseObject => r.body)
    );
  }

  /** Path part for operation `sendFuelCardOrderMail()` */
  static readonly SendFuelCardOrderMailPath = '/sendMail/fuelcard-order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendFuelCardOrderMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendFuelCardOrderMail$Response(params: SendFuelCardOrderMail$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseObject>> {
    return sendFuelCardOrderMail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendFuelCardOrderMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendFuelCardOrderMail(params: SendFuelCardOrderMail$Params, context?: HttpContext): Observable<ResponseObject> {
    return this.sendFuelCardOrderMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseObject>): ResponseObject => r.body)
    );
  }

  /** Path part for operation `sendEetsOrderRequestMail()` */
  static readonly SendEetsOrderRequestMailPath = '/sendMail/eets-order-request';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendEetsOrderRequestMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendEetsOrderRequestMail$Response(params: SendEetsOrderRequestMail$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseObject>> {
    return sendEetsOrderRequestMail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendEetsOrderRequestMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendEetsOrderRequestMail(params: SendEetsOrderRequestMail$Params, context?: HttpContext): Observable<ResponseObject> {
    return this.sendEetsOrderRequestMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseObject>): ResponseObject => r.body)
    );
  }

  /** Path part for operation `sendEetsOrderOfferMail()` */
  static readonly SendEetsOrderOfferMailPath = '/sendMail/eets-order-offer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendEetsOrderOfferMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendEetsOrderOfferMail$Response(params: SendEetsOrderOfferMail$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseObject>> {
    return sendEetsOrderOfferMail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendEetsOrderOfferMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendEetsOrderOfferMail(params: SendEetsOrderOfferMail$Params, context?: HttpContext): Observable<ResponseObject> {
    return this.sendEetsOrderOfferMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseObject>): ResponseObject => r.body)
    );
  }

  /** Path part for operation `sendEetsOrderConfirmedMail()` */
  static readonly SendEetsOrderConfirmedMailPath = '/sendMail/eets-order-confirmed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendEetsOrderConfirmedMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendEetsOrderConfirmedMail$Response(params: SendEetsOrderConfirmedMail$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseObject>> {
    return sendEetsOrderConfirmedMail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendEetsOrderConfirmedMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendEetsOrderConfirmedMail(params: SendEetsOrderConfirmedMail$Params, context?: HttpContext): Observable<ResponseObject> {
    return this.sendEetsOrderConfirmedMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseObject>): ResponseObject => r.body)
    );
  }

  /** Path part for operation `sendDkvEInvoicingMail()` */
  static readonly SendDkvEInvoicingMailPath = '/sendMail/dkv-einvoicing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendDkvEInvoicingMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendDkvEInvoicingMail$Response(params: SendDkvEInvoicingMail$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseObject>> {
    return sendDkvEInvoicingMail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendDkvEInvoicingMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendDkvEInvoicingMail(params: SendDkvEInvoicingMail$Params, context?: HttpContext): Observable<ResponseObject> {
    return this.sendDkvEInvoicingMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseObject>): ResponseObject => r.body)
    );
  }

  /** Path part for operation `sendCustomerBusinessAreaLeadMail()` */
  static readonly SendCustomerBusinessAreaLeadMailPath = '/sendMail/customer-businessarea-lead';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendCustomerBusinessAreaLeadMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendCustomerBusinessAreaLeadMail$Response(params: SendCustomerBusinessAreaLeadMail$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseObject>> {
    return sendCustomerBusinessAreaLeadMail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendCustomerBusinessAreaLeadMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendCustomerBusinessAreaLeadMail(params: SendCustomerBusinessAreaLeadMail$Params, context?: HttpContext): Observable<ResponseObject> {
    return this.sendCustomerBusinessAreaLeadMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseObject>): ResponseObject => r.body)
    );
  }

  /** Path part for operation `sendCrefoMailboxMail()` */
  static readonly SendCrefoMailboxMailPath = '/sendMail/crefo-mailbox';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendCrefoMailboxMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendCrefoMailboxMail$Response(params: SendCrefoMailboxMail$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseObject>> {
    return sendCrefoMailboxMail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendCrefoMailboxMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendCrefoMailboxMail(params: SendCrefoMailboxMail$Params, context?: HttpContext): Observable<ResponseObject> {
    return this.sendCrefoMailboxMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseObject>): ResponseObject => r.body)
    );
  }

  /** Path part for operation `sendContactMail()` */
  static readonly SendContactMailPath = '/sendMail/contact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendContactMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendContactMail$Response(params: SendContactMail$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseObject>> {
    return sendContactMail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendContactMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendContactMail(params: SendContactMail$Params, context?: HttpContext): Observable<ResponseObject> {
    return this.sendContactMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseObject>): ResponseObject => r.body)
    );
  }

  /** Path part for operation `sendConditionsAdjustmentMail()` */
  static readonly SendConditionsAdjustmentMailPath = '/sendMail/conditions-adjustment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendConditionsAdjustmentMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendConditionsAdjustmentMail$Response(params: SendConditionsAdjustmentMail$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseObject>> {
    return sendConditionsAdjustmentMail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendConditionsAdjustmentMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendConditionsAdjustmentMail(params: SendConditionsAdjustmentMail$Params, context?: HttpContext): Observable<ResponseObject> {
    return this.sendConditionsAdjustmentMail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseObject>): ResponseObject => r.body)
    );
  }

}
