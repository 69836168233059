/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { DefaultWorkingTimeOverdraftControllerService } from './services/default-working-time-overdraft-controller.service';
import { UsersControllerService } from './services/users-controller.service';
import { ParticipantsControllerService } from './services/participants-controller.service';
import { TrainingCustomerControllerService } from './services/training-customer-controller.service';
import { TagControllerService } from './services/tag-controller.service';
import { SvgInfoAdminControllerService } from './services/svg-info-admin-controller.service';
import { StandardTrainingControllerService } from './services/standard-training-controller.service';
import { ShtTrainingControllerService } from './services/sht-training-controller.service';
import { ScopeOfCareControllerService } from './services/scope-of-care-controller.service';
import { RiskAssessmentTemplateControllerService } from './services/risk-assessment-template-controller.service';
import { NationwideProjectsControllerService } from './services/nationwide-projects-controller.service';
import { FireSafetyTrainingControllerService } from './services/fire-safety-training-controller.service';
import { TradeAuthorityOfficeControllerService } from './services/trade-authority-office-controller.service';
import { RiskPriorityDetailsControllerService } from './services/risk-priority-details-controller.service';
import { RiskAssessmentControllerService } from './services/risk-assessment-controller.service';
import { OccupationalInsuranceAssociationCustomerInfoControllerService } from './services/occupational-insurance-association-customer-info-controller.service';
import { MeasuresControllerService } from './services/measures-controller.service';
import { EmergencyAlertPlanControllerService } from './services/emergency-alert-plan-controller.service';
import { CustomerContractsControllerService } from './services/customer-contracts-controller.service';
import { WorkingTimeControllerService } from './services/working-time-controller.service';
import { RepeatingAttendancesControllerService } from './services/repeating-attendances-controller.service';
import { PriceConditionControllerService } from './services/price-condition-controller.service';
import { OccupationalSafetyAndHealthcareControllerService } from './services/occupational-safety-and-healthcare-controller.service';
import { TransportCarrierControllerService } from './services/transport-carrier-controller.service';
import { AccountabilityControllerService } from './services/accountability-controller.service';
import { ContactPersonControllerService } from './services/contact-person-controller.service';
import { CompanyDoctorControllerService } from './services/company-doctor-controller.service';
import { CustomerBranchOfficeControllerService } from './services/customer-branch-office-controller.service';
import { MedicalCenterControllerService } from './services/medical-center-controller.service';
import { CustomerContactControllerService } from './services/customer-contact-controller.service';
import { TeamsControllerService } from './services/teams-controller.service';
import { NewsletterControllerService } from './services/newsletter-controller.service';
import { CustomerOverviewControllerService } from './services/customer-overview-controller.service';
import { CustomerVehicleQuantityControllerService } from './services/customer-vehicle-quantity-controller.service';
import { HealthSafetyOrganizationControllerService } from './services/health-safety-organization-controller.service';
import { HazardControllerService } from './services/hazard-controller.service';
import { RegulationControllerService } from './services/regulation-controller.service';
import { EconomicBranchControllerService } from './services/economic-branch-controller.service';
import { AddressControllerService } from './services/address-controller.service';
import { InitialLetterControllerService } from './services/initial-letter-controller.service';
import { CustomerOverviewExportControllerService } from './services/customer-overview-export-controller.service';
import { CollectiveBillingControllerService } from './services/collective-billing-controller.service';
import { ActivitiesControllerService } from './services/activities-controller.service';
import { SecurityExpertControllerService } from './services/security-expert-controller.service';
import { SecurityExpertsNewCustomersControllerService } from './services/security-experts-new-customers-controller.service';
import { CustomerSecurityExpertControllerService } from './services/customer-security-expert-controller.service';
import { HazardFactorControllerService } from './services/hazard-factor-controller.service';
import { CompanyDescriptionControllerService } from './services/company-description-controller.service';
import { CustomerActivationControllerService } from './services/customer-activation-controller.service';
import { MySvgControllerService } from './services/my-svg-controller.service';
import { VehicleTypeControllerService } from './services/vehicle-type-controller.service';
import { TrainingTypesControllerService } from './services/training-types-controller.service';
import { TrainingSubjectControllerService } from './services/training-subject-controller.service';
import { ParticipationCategoryControllerService } from './services/participation-category-controller.service';
import { TrainingMethodsControllerService } from './services/training-methods-controller.service';
import { SvgInfoControllerService } from './services/svg-info-controller.service';
import { SvgInfoAdminSecurityExpertControllerService } from './services/svg-info-admin-security-expert-controller.service';
import { SecurityExpertTeamControllerService } from './services/security-expert-team-controller.service';
import { ContactSalutationControllerService } from './services/contact-salutation-controller.service';
import { MinimalCustomerControllerService } from './services/minimal-customer-controller.service';
import { CustomerContingentControllerService } from './services/customer-contingent-controller.service';
import { AddressTypeControllerService } from './services/address-type-controller.service';
import { CustomerBusinessSectorControllerService } from './services/customer-business-sector-controller.service';
import { CustomerBillableServicesLongAgoControllerService } from './services/customer-billable-services-long-ago-controller.service';
import { CustomerTypeControllerService } from './services/customer-type-controller.service';
import { CountryControllerService } from './services/country-controller.service';
import { ContactSvgRoleControllerService } from './services/contact-svg-role-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    DefaultWorkingTimeOverdraftControllerService,
    UsersControllerService,
    ParticipantsControllerService,
    TrainingCustomerControllerService,
    TagControllerService,
    SvgInfoAdminControllerService,
    StandardTrainingControllerService,
    ShtTrainingControllerService,
    ScopeOfCareControllerService,
    RiskAssessmentTemplateControllerService,
    NationwideProjectsControllerService,
    FireSafetyTrainingControllerService,
    TradeAuthorityOfficeControllerService,
    RiskPriorityDetailsControllerService,
    RiskAssessmentControllerService,
    OccupationalInsuranceAssociationCustomerInfoControllerService,
    MeasuresControllerService,
    EmergencyAlertPlanControllerService,
    CustomerContractsControllerService,
    WorkingTimeControllerService,
    RepeatingAttendancesControllerService,
    PriceConditionControllerService,
    OccupationalSafetyAndHealthcareControllerService,
    TransportCarrierControllerService,
    AccountabilityControllerService,
    ContactPersonControllerService,
    CompanyDoctorControllerService,
    CustomerBranchOfficeControllerService,
    MedicalCenterControllerService,
    CustomerContactControllerService,
    TeamsControllerService,
    NewsletterControllerService,
    CustomerOverviewControllerService,
    CustomerVehicleQuantityControllerService,
    HealthSafetyOrganizationControllerService,
    HazardControllerService,
    RegulationControllerService,
    EconomicBranchControllerService,
    AddressControllerService,
    InitialLetterControllerService,
    CustomerOverviewExportControllerService,
    CollectiveBillingControllerService,
    ActivitiesControllerService,
    SecurityExpertControllerService,
    SecurityExpertsNewCustomersControllerService,
    CustomerSecurityExpertControllerService,
    HazardFactorControllerService,
    CompanyDescriptionControllerService,
    CustomerActivationControllerService,
    MySvgControllerService,
    VehicleTypeControllerService,
    TrainingTypesControllerService,
    TrainingSubjectControllerService,
    ParticipationCategoryControllerService,
    TrainingMethodsControllerService,
    SvgInfoControllerService,
    SvgInfoAdminSecurityExpertControllerService,
    SecurityExpertTeamControllerService,
    ContactSalutationControllerService,
    MinimalCustomerControllerService,
    CustomerContingentControllerService,
    AddressTypeControllerService,
    CustomerBusinessSectorControllerService,
    CustomerBillableServicesLongAgoControllerService,
    CustomerTypeControllerService,
    CountryControllerService,
    ContactSvgRoleControllerService,
    ApiConfiguration
  ],
})
export class CustomersSysteminfoModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<CustomersSysteminfoModule> {
    return {
      ngModule: CustomersSysteminfoModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: CustomersSysteminfoModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('CustomersSysteminfoModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
